<template>
 <div v-if="perms.includes('Facturation Fournisseurs') || perms.includes('Admin')">
    <div v-if="loading">
    <div id="loading-bg">

      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  </div>
  <section class="invoice-add-wrapper" v-else>
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8" >
       
        <b-form @submit.prevent id="formdata">
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <!-- <logo /> -->
                    <h3 class="text-primary invoice-logo">
                      Facture Fournisseur
                    </h3>
                  </div>
                  <!-- <b-card-text class="mb-25">
                    AGADIR 
                  </b-card-text>
                  <b-card-text class="mb-25">
                    Adresse
                  </b-card-text>
                  <b-card-text class="mb-0">
                    +212 0645743797
                  </b-card-text> -->
                </div>

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Facture N°
                    </h4>
                    <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="invoice-data-id"
                        v-model="rows.NumFact"                        
                      />
                    </b-input-group>
                  </div>
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Date:
                    </h4>
                    <b-form-input
                          v-model="rows.DateFact"
                          type="date"
                         />
                    <!-- <flat-pickr
                      v-model="rows.DateFact"
                      class="form-control invoice-edit-input"
                    /> -->
                    <!-- <b-input-group class="mb-1" style="width: 50%;">
                      <b-form-input
                        
                        id="example-input"
                        v-model="rows.DateFact"
                        type="text"
                        autocomplete="off"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          format='dd/mm/yyyy'
                          v-model="rows.DateFact"
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          locale="fr"
                          aria-controls="example-input"
                          
                        />
                      </b-input-group-append>
                      </b-input-group> -->
                  </div>
                  <!-- <div class="d-flex align-items-center">
                    <span class="title">
                      Due Date:
                    </span>
                    <flat-pickr
                      v-model="invoiceData.dueDate"
                      class="form-control invoice-edit-input"
                    />
                  </div> -->
                </div>
              </div>
            </b-card-body>
                   <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="rows.Products"
            :fields="['ref_prod', 'Nom', 'quantite', 'Prix_Achat','Total']"
          >
            <template #cell(ref_prod)="data">
              <b-card-text class="font-weight-bold mb-25">
                <b>{{ data.item.ref_prod }}</b>
              </b-card-text>
            </template>
            <template #cell(quantite)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.qnt }}
              </b-card-text>
            </template>

            <template #cell(Prix_Achat)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.Prix_Achat }} DH
              </b-card-text>
            </template>

             <template #cell(Total)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.tot }} DH
              </b-card-text>
            </template>
          </b-table-lite>
            <!-- Invoice Client & Payment Details -->
            
            <!-- Items Section -->
            
            <b-card-body class="invoice-padding form-item-section">
                  <div
                    ref="form"
                    class="repeater-form"
                  >
                  <!-- :style="{height: trHeight}" -->

                    <b-row
                      v-for="(item, index) in FactureData.produits"
                      :key="index"
                      ref="row"
                      class="pb-2"
                    >

                      <!-- Item Form -->
                      <!-- ? This will be in loop => So consider below markup for single item -->
                      <b-col cols="12">

                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                        <div class="d-none d-lg-flex">
                          <b-row class="flex-grow-1 px-1">
                            <!-- Single Item Form Headers -->
                            <b-col
                              cols="12"
                              lg="5"
                            >
                              Produit
                            </b-col>
                            <b-col
                              cols="12"
                              lg="3"
                            >
                              Prix Achat
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              Quantité
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              Total H.T
                            </b-col>
                          </b-row>
                          <div class="form-item-action-col" />
                        </div>

                        <!-- Form Input Fields OR content inside bordered area  -->
                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                        <div class="d-flex border rounded">
                          <b-row class="flex-grow-1 p-2">
                            <!-- Single Item Form Headers -->
                            <b-col
                              cols="12"
                              lg="5"
                            >
                              <label class="d-inline d-lg-none">Produit</label>
                              <!--  v-model="item.Nom" -->
                              <v-select style="border: 1px solid #0000002b;  border-radius: 0.357rem;"
                               
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="Products"
                                label="Nom"
                                :clearable="false"
                                class="mb-2 item-selector-title"
                                placeholder="Select Item"
                                @input="val => updateItemForm(index, val)"
                              />
                            </b-col>
                            <b-col
                              cols="12"
                              lg="3"
                            >
                              <label class="d-inline d-lg-none">Prix Achat</label>
                              <b-form-input
                                v-model="item.Prix_Achat"
                                type="number"
                                class="mb-2"
                                disabled
                              />
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Quantité</label>
                               <validation-provider
                                #default="{ errors }"
                                name="Quantité"
                                rules="required|integer|min_value:1">
                              <b-form-input
                                v-model="item.qnt"
                                type="number"
                                class="mb-2"
                                :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Total H.T</label>
                              <p class="mb-1" v-if="item.qnt!=''">
                                {{ item.Prix_Achat * item.qnt }} DH
                              </p>
                              <p v-else class="mb-1" >
                                0 DH
                              </p>
                            </b-col>
                           
                          </b-row>
                          <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                            <feather-icon
                              size="16"
                              icon="XIcon"
                              class="cursor-pointer"
                              @click="removeItem(index)"
                            />
                            <!-- <feather-icon
                              :id="`form-item-settings-icon-${index}`"
                              size="16"
                              icon="SettingsIcon"
                              class="cursor-pointer"
                            /> -->

                            <!-- Setting Item Form -->
                           
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    @click="addNewItemInItemForm" >
                    Ajouter Produit
                  </b-button>
                </b-card-body>
            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
            
                <b-col
                  cols="12"
                  md="4"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1">
                <b-card-body class="pt-0">
                 <span class="font-weight-bold">Total H.T: </span>
               <b-form-input
                    v-model="rows.TotalHT"        
                    type="number"
                    class="mb-2"
                              />
                </b-card-body>
                </b-col>
                 <b-col
                  cols="12"
                  md="4"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                 <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">TVA : </span>
                <select class="form-control" style="margin-bottom: 20px;" 
                    v-model="rows.tva" @input="getNet()">
                          <option value="7">7%</option>
                          <option value="10">10%</option>
                          <option value="14">14%</option>
                          <option value="20">20%</option>
                      </select>
               <!-- <b-form-input
                    v-model="rows.tva"            
                    type="number"
                    @input="getNet()"
                    class="mb-2" /> -->
                 </b-card-body>
                </b-col>
                 <b-col
                  cols="12"
                  md="4"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                 <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">NET A PAYER TTC: </span>
               <b-form-input
                    v-model="rows.TotalNet"      
                    type="text"
                    class="mb-2"
                              />
                 </b-card-body>
                </b-col>
                 <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex "
                  order="2"
                  order-md="1"
                >
                    <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">La présence facture a la somme de  </span>
              <b-form-textarea v-model="rows.somme" placeholder="CINQ MILLE DHS Z2RO CTS" />
            </b-card-body>
                 </b-col>
                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                  <!-- <label
                    for="invoice-data-sales-person"
                    class="font-weight-bold mr-50"
                  >Mode de reglement :</label> -->
                  <b-card-body class="invoice-padding pt-0">
                   <span class="font-weight-bold">Mode de reglement :  </span>
                  <b-form-input
                    id="invoice-data-sales-person"
                    placeholder="Espece/Cheque"
                    v-model="rows.reglemment"
                  />
                  </b-card-body>
                </b-col>
                <!-- <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                 
                </b-col> -->
              </b-row>
            </b-card-body>
          </b-card>
          
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
         <b-col
              cols="12"
              md="4"
              xl="3"
              class="invoice-actions mt-md-0 mt-2"
            >
              <b-card>
                   <!-- <b-card> -->
                <h5  v-if="rows.items.length!=0" class="mb-2">
                  Pièces Jointes
                </h5>

                <!-- suggested pages --> 
                 <!--  -->
                <div
                  v-for="(item,index) in rows.items"
                  :key="index"
                  class="d-flex justify-content-start align-items-center mt-1"
                >
                  <div class="mr-1" v-if="substr(item.name)==='pdf'">
                    <b-avatar
                      size="40"
                      :src="require('@/assets/images/logo/pdfLogo.png')"
                    />
                  </div>
                  <div  class="mr-1" v-else>
                    <b-avatar
                      size="40"
                      :src="require('@/assets/images/logo/imglogo.png')"
                    />
                  </div>
                  <div class="profile-user-info">
                    <h6 class="mb-0" v-if="substr(item.name)==='pdf'">
                     PDF
                    </h6>
                    <h6 class="mb-0" v-else>
                     Image
                    </h6>
                    <small class="text-muted">PJ {{index+1}}</small>
                  </div>
                  <div class="profile-star ml-auto">
                    <button style="display: contents;">
                     <feather-icon style="margin-right: 5px;"
                    icon="EyeIcon"
                    class="mr-90"
                    size="19"
                    @click="VoirItem(item.name)" 
                    />
                    </button>
                    <button style="display: contents;">
                     <feather-icon 
                    icon="XIcon"
                    class="mr-90"
                    size="19"
                    @click="DeleteItem(item.id)" 
                    />
                    </button>
                  </div>
                </div>
                <!--/ suggested pages -->
              <!-- </b-card> -->
              <!-- <br/> -->
                <hr/>
                <input type="file"  accept="application/pdf,image/*" class="hidden" ref="updateImgInput" multiple="multiple" @change="imageSelected" >
              <b-button style="margin-bottom: 20px;"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                block
                @click="$refs.updateImgInput.click()"
              >
                Autres Pièces Jointes
              </b-button>
              <b-media-aside>
                  <div v-for="(attachment, index) in attachments " :key="index">
                    <span class="label label-primary">{{ attachment.name + ' (' + Number((attachment.size / 1024 / 1024).toFixed(1)) + 'MB)'}}<br></span>
                  </div>
            </b-media-aside>
             
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mb-75"
                  block
                  @click="UpdateFacture()"
                  
                >
                  Modifier La Facture
                </b-button>
              </b-card>
           
        </b-col>
    </b-row>
  </section>
  </div>
  <div v-else>
  <not-authoriz></not-authoriz>
</div>
</template>

<script>
import config from '@/config'
import moment from 'moment';
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, 
  BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle,BTableLite,
  BMedia, BMediaAside, BMediaBody, BLink,BImg,BAvatar,BFormDatepicker, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import invoiceStoreModule from './invoiceStoreModule'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum,min_value } from '@validations'
// import InvoiceSidebarAddNewCustomer from '../InvoiceSidebarAddNewCustomer.vue'
import NotAuthoriz from '../NotAuthoriz.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BMedia, BMediaAside, BMediaBody, BLink,BImg,BTableLite,
    flatPickr,
    vSelect,
    Logo,ValidationProvider, ValidationObserver ,NotAuthoriz,BAvatar,BFormDatepicker, BInputGroupAppend,
    // InvoiceSidebarAddNewCustomer,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  data(){
      return{
        path:config.path,
        perms:[],
        required, alphaNum,min_value,
        loading:false,
          rows:{},
          Products:[],
          NumFact:'',
          totalHT:0,
          totalNET:0,
          tva:0,
          somme:'',
          reglemment:'',
          dateFact:'',
          fourniId:0,
          image:null,
          imagepreview:null,
          attachments:[],
          count:0,
          UserID:{},
          config: {
                    wrap: true, // set wrap to true only when using 'input-group'
                    dateFormat: 'd/m/Y',
                },


      }
  },
  mounted() {
    this.initTrHeight()
    this.perms=JSON.parse(localStorage.getItem('access'));
    this.getFactFourni();  
    this.UserID=JSON.parse(localStorage.getItem('userData'))    
    // this.$http.post(`${config.API_BASE_URL}/api/auth/getProductFourni/2`)
    //   .then(res => { this.Products = res.data });
     
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
   
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    DeleteItem(id){
      let data= new FormData;
       data.append('UserID', this.UserID.id);
      this.$swal.fire({
          text: "Vous voulez supprimer cette pièce jointe ?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Oui, supprimer!'
          }).then((result) => {
            if (result.value) {
                this.$http.post(`${config.API_BASE_URL}/api/auth/DeleteAttach/${id}`,data)
                    .then(res=> {
                        if(res.data=='Success'){
                          this.getFactFourni();
                        this.$swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'La pièce est bien supprimée',
                        showConfirmButton: false,
                        timer: 1500  })   
                        } 
                    }).catch(() => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong! Pleaz try again'
                            });
                    })
                }             
            })
    },
    VoirItem(item){
      window.open(this.path+'attachements/'+item, '_blank');
    },
    substr(val){
      return val.substr(-3);
    },
    imageSelected(e){       
      let selectedFiles=e.target.files;
          if(!selectedFiles.length){
              return false;
          }
          for(let i=0;i<selectedFiles.length;i++){
              this.attachments.push(selectedFiles[i]);
          }
          // console.log(this.attachments);
         },
      addfourni(){
          this.$router.push('/AddNewFournis');
      },
      getFactFourni(){
        this.loading=true
          this.$http.get(`${config.API_BASE_URL}/api/auth/ShowFactFR/${router.currentRoute.params.id}`)
      .then(res => { this.rows = res.data,   this.loading=false ,
      this.count=Object.keys(res.data).length;
      this.$http.post(`${config.API_BASE_URL}/api/auth/getProductFourni/${res.data.fournis_id}`)
      .then(response => { this.Products = response.data });
      });
      },

      UpdateFacture(){
        let data = new FormData;
          data.append('UserID', this.UserID.id);
          data.append('products', JSON.stringify(this.FactureData.produits));
          data.append('NumFact', this.rows.NumFact);
        //   data.append('fournis_id', this.rows.fournis_id);
          data.append('TotalHT', this.rows.TotalHT);
          data.append('TotalNet', this.rows.TotalNet);
          data.append('tva', this.rows.tva);
          data.append('somme', this.rows.somme);
          data.append('reglemment', this.rows.reglemment);
          data.append('DateFact', this.rows.DateFact);

          // data.append('DateFact', moment(String(this.rows.DateFact)).format('DD/MM/YYYY'));
           for(let i=0; i<this.attachments.length;i++){
                data.append('attachments[]',this.attachments[i]);
                }
                console.log(this.FactureData.produits)
          this.$http.post(`${config.API_BASE_URL}/api/auth/EditFactFR/${router.currentRoute.params.id}`,data)
            .then(res => { 
                if(res.data=='success'){
                    this.getFactFourni();
                    this.$swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Votre facture est bien modifée',
                  showConfirmButton: false,
                  timer: 1000 })
                //   document.getElementById("formdata").reset();
                  this.attachments=[];
                  this.FactureData.produits=[];
                  this.$router.push({ name: 'fact-fourni-preview', params: { id: router.currentRoute.params.id }})

                }else{
                      this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Pleaz try again' })
                }
                
                })

         
        //   AddFactFR
      },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.FactureData.produits.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))
    this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.FactureData.produits.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    getNet(){
      console.log(typeof parseFloat(this.rows.TotalNet))
        this.rows.TotalNet=parseFloat(this.rows.TotalHT*(1-this.rows.tva/100)).toFixed(2)
        
        let numberInput = parseFloat(this.rows.TotalNet) ;
        //  console.log(typeof numberInput)
        let myDiv = document.querySelector('#result');
        let oneToTwenty = ['','un ','deux ','trois ','quatre ', 'cinq ','six ','sept ','huit ','neuf ','dix ',
        'onze ','douze ','treize ','quatorze ','quatorze ','seize ','dix-sept ','dix-huit ','dix-neuf '];
        let tenth = ['', '', 'vingt','trente','quarante','cinquante', 'soixante','soixante-dix','quatre-vingts','quatre-vingt-dix'];
        if(numberInput.toString().length > 7) return myDiv.innerHTML = 'overlimit' ;
        // console.log(numberInput);
        //let num = ('0000000000'+ numberInput).slice(-10).match(/^(\d{1})(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      let num = ('0000000'+ numberInput).slice(-7).match(/^(\d{1})(\d{1})(\d{2})(\d{1})(\d{2})$/);
        console.log(num);
        if(!num) return;
        let outputText = num[1] != 0 ? (oneToTwenty[Number(num[1])] || `${tenth[num[1][0]]} ${oneToTwenty[num[1][1]]}` )+' million ' : ''; 
      
        outputText +=num[2] != 0 ? (oneToTwenty[Number(num[2])] || `${tenth[num[2][0]]} ${oneToTwenty[num[2][1]]}` )+'cent ' : ''; 
        outputText +=num[3] != 0 ? (oneToTwenty[Number(num[3])] || `${tenth[num[3][0]]} ${oneToTwenty[num[3][1]]}`)+' mille ' : ''; 
        outputText +=num[4] != 0 ? (oneToTwenty[Number(num[4])] || `${tenth[num[4][0]]} ${oneToTwenty[num[4][1]]}`) +'cent ': ''; 
        outputText +=num[5] != 0 ? (oneToTwenty[Number(num[5])] || `${tenth[num[5][0]]} ${oneToTwenty[num[5][1]]} `) : ''; 

        // myDiv.innerHTML = outputText;
        this.rows.somme=outputText;
        // console.log(outputText);
      },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const clients = ref([])
    store.dispatch('app-invoice/fetchClients')
      .then(response => { clients.value = response.data })

    const itemFormBlankItem = {
      Nom: null,
      id:0,
      Prix_Achat: 0,
      qnt: 0,
    }
    
    const FactureData= ref({
        produits :[JSON.parse(JSON.stringify(itemFormBlankItem))],
    })
    const invoiceData = ref({
      id: 5037,
      client: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
    })

    
     const updateItemForm = (index, val) => {
      const { Prix_Achat, id, Nom } = val
      FactureData.value.produits[index].Prix_Achat = Prix_Achat
      FactureData.value.produits[index].id = id
      FactureData.value.produits[index].Nom = Nom
    //   FactureData.value.produits[index].total = Prix_Achat*quantite
    }



    return {
      FactureData,
      invoiceData,
      clients,
      updateItemForm,
      itemFormBlankItem,
    }
  },
}
</script>


<style scoped>
.media-aside.align-self-start {
    display: grid;
}
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
